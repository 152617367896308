import { Injectable } from '@angular/core';
import { UtilisService } from './services/utilis.service';
import { API } from 'aws-amplify';
import { Procedure } from './modals/Procedure';
import { Team } from './modals/Team';
import { UserService } from './services/user.service';
import { Role } from './modals/Role';
import { Folder } from './modals/Folder';
// import ClassicEditor from '../assets/ckeditor5';

@Injectable({
  providedIn: 'root'
})
export class DataCollectorService {
  // public Editor = ClassicEditor
  updateProcedure: Procedure = new Procedure();
  query: string = '';
  account: any = {};
  project: any = {};
  contact: any = {};
  team: Team[] = [];
  current: Team[] = [];
  unit: any = {};
  procedure: Procedure = new Procedure();
  fileType: string = '';
  index: number;
  note: string;
  states: Array<string> = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
  allRuns: Procedure[] = [];
  allTeam: Team[] = [];
  milestoneIndex: number;
  mileIndex: number;
  updateTaskIndex: number;
  createRun: boolean;
  procedureMilestoneIndex:string = '';
  updateTask:boolean = false;
  loading:boolean = false;
  roles:Role[] = []
  folder:Folder = new Folder();
  folderPath:string;
  folderPaths = [];
  folders:Folder[] = [];
  storeFolder:Folder[] = [];
  showRoot:boolean = false;
  moveProcedureData:Procedure = new Procedure();
  showModalForLink:boolean = false;
  showEmailModal:boolean = false;
  // noDisplayForPrint:boolean=true;

  checkForRunCreated:boolean = false;

  constructor(public utilis:UtilisService,
    public userService:UserService) { }
  
  getRoles() {
    return new Promise((resolve, reject) => {
      API.get("RolesApi", `/roles/${this.userService.username}`, { body: {} })
      .then(res => {
        this.roles = res.filter(x => !x.isDeleted);
        resolve(this.roles);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
    })
    // this.loading = true;
    
  }

  async getTeam() {
    return new Promise((resolve, reject) => {
      API.get("TeamApi", `/team/team`, { body: {} })
      .then(async res => {
        // console.log('all team is ', res);
        this.allTeam = res;
        // console.log(this.userService.currentUser, "CurrentUser")
        this.team = res.filter(x => x.adminId === this.userService.username && !x.isDeleted);
        this.current = this.team.filter(x => x.email == this.userService.currentUser.email);
        resolve(this.team);
      })
      .catch(err => {
        console.log(err);
        reject(err);

      });
    })
    
  }

  copyLink(link) {
    navigator.clipboard.writeText(link).then(res=>{
      this.utilis.presentToast('Link copy to clipboard');
    }).catch(e => console.error(e));
  }

  // change the data into form data
  async getFormData(object) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(object).forEach(key => {
        if((key == 'profile_image' || key == 'attachment') && object[key] != null){
          formData.append(key, object[key],'image.'+object[key].type.split('/')[1]);
        }else{
          formData.append(key, object[key])
        }
      
      });
      resolve(formData);
    })
  }

}
