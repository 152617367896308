/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b5921240-5fbc-4399-8897-f90814fb42c5",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_6IAD7AjxM",
    "aws_user_pools_web_client_id": "40tv6tvnmo8g8f3qke8bm8q5c4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "accounts-test",
            "region": "us-east-2"
        },
        {
            "tableName": "projects-test",
            "region": "us-east-2"
        },
        {
            "tableName": "units-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Procedures-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Contacts-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Team-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Teams-test",
            "region": "us-east-2"
        },
        {
            "tableName": "RolesTable-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Events-test",
            "region": "us-east-2"
        },
        {
            "tableName": "Folders-test",
            "region": "us-east-2"
        },
        {
            "tableName": "SettingsDb-test",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "qct-filestest-test",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AccountsApi",
            "endpoint": "https://esqq4rsq7l.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "ProjectsApi",
            "endpoint": "https://2bc9clv90f.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "UnitsApi",
            "endpoint": "https://yvpcny5h21.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "ProceduresApi",
            "endpoint": "https://9yfdimp0qa.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "ContactsApi",
            "endpoint": "https://qqdtnmhekg.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "TeamApi",
            "endpoint": "https://1at8l7ntof.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "TeamsApi",
            "endpoint": "https://i4wimayd0i.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "RolesApi",
            "endpoint": "https://xf164yhjfb.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "EventsApi",
            "endpoint": "https://hh2h1yjd21.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "FoldersApi",
            "endpoint": "https://k0odhz0hi2.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        },
        {
            "name": "SettingsApi",
            "endpoint": "https://ydrd38u6b7.execute-api.us-east-2.amazonaws.com/test",
            "region": "us-east-2"
        }
    ],
    "aws_content_delivery_bucket": "dev-hosting-test",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://dev-hosting-test.s3-website.us-east-2.amazonaws.com"
};


export default awsmobile;
