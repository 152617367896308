import { Component } from '@angular/core';
import { Platform, Events, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './services/user.service';
import { Auth } from 'aws-amplify';
import { Team } from './modals/Team';
import { DataCollectorService } from './data-collector.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  
})
export class AppComponent {

  name: string = "";
  user: any = {
    email: "",
    logo: ""
  }

  public appPages = [
    {
      title: 'Home',
      url: '/home',
    },
    {
      title: 'Reporting',
      url: '/reporting',
    },
    {
      title: 'Help',
      url: '/help',
    },
    {
      title: 'Settings',
      url: '/settings',
    },
    {
      title: 'Logout',
      url: '/login',
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private nav: NavController,
    private events: Events,
    private menuCtrl: MenuController,
    public userService: UserService,
    public service: DataCollectorService
  ) {
    this.initializeApp();
    this.events.subscribe('userUpdated', (val) => {
      this.user.email = localStorage.getItem("email");
      this.user.logo = localStorage.getItem("logo") || "";
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.statusBar.backgroundColorByHexString('#f1f1f1');
      
      if (this.userService.isLoggedIn) {
        this.nav.navigateRoot(['/home']);
      }

      /* if (localStorage.getItem("uid")) {
        this.nav.navigateRoot(['/home']);
        this.name = localStorage.getItem("name");
        this.user.email = localStorage.getItem("email");
        this.user.logo = localStorage.getItem("logo") || "";
      } */
    });
  }

  navigatePage(page) {
    this.service.folderPaths = [];
    if (page.title == 'Logout') {
      this.logout();
    }
    else {
      this.nav.navigateRoot([page.url]);
    }
  }


  logout() {
    Auth.signOut();
    localStorage.removeItem('currentUserEmail');
    this.userService.teamUser = new Team();
  }


  menuToggle() {
    this.menuCtrl.toggle();
  }

  deleteAccount() {
    var buttons = [
      {
        text: 'No',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          return;
        }
      }, {
        text: 'Yes',
        handler: () => {
          this.userService.deleteAccount();
        }
      }
    ]
    this.userService.presentAlert("Alert", null,"Are you sure want to delete your account? You cannot undo this!", "",buttons);
    
  }
}
